<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        to="/"
      >
        <!-- <vuexy-logo /> -->
        <custom-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ navbarTitle }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-overlay
          :show="show"
          rounded="sm"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              {{ $t('Welcome to') }} {{ navbarTitle }}! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('Please sign-in to your account') }}
            </b-card-text>
            <b-tabs align="right">
              <b-tab
                :active="type == 'root' ? true : false"
                :title="$t('Root')"
                @click="changeType('root')"
              />
              <b-tab
                :active="type == 'agent' ? true : false"
                :title="$t('Agent')"
                @click="changeType('agent')"
              />
            </b-tabs>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- username -->
                <b-form-group
                  :label="$t('Username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      :placeholder="$t('Username')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("Password") }}</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>{{ $t("Forgot Password") }}?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="$t('Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="remember"
                    name="checkbox-1"
                  >
                    {{ $t("Remember Me") }}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t("Sign in") }}
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>{{ $t("You don't have an account yet") }}? </span>
              <b-link :to="{ name: 'auth-register' }">
                <span>&nbsp;{{ $t("Create an account") }}</span>
              </b-link>
            </b-card-text>

            <!-- divider -->
            <div class="divider my-2">
              <div class="divider-text">
                {{ $t("or") }}
              </div>
            </div>

            <!-- social buttons -->
            <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div>
          </b-col>
        </b-overlay>

      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CustomLogo from '@/Logo.vue'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, rootAbility, agentAbility,
} from '@/libs/acl/config'
// eslint-disable-next-line no-unused-vars
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    CustomLogo,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // eslint-disable-next-line no-unneeded-ternary
      remember: process.env.NODE_ENV === 'production' ? false : true,
      username: process.env.NODE_ENV === 'production' ? '' : 'bigponzue',
      password: process.env.NODE_ENV === 'production' ? '' : '1121',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      show: false,
      type: localStorage.getItem('buttonLogin') ? localStorage.getItem('buttonLogin') : 'agent',
      // validation rules
      required,
    }
  },
  computed: {
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    changeType(type) {
      this.type = type
      localStorage.setItem('buttonLogin', type)
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.show = true
          useJwt
            .login({
              username: this.username,
              password: this.password,
              remember: this.remember,
              type: this.type,
            })
            .then(response => {
              this.show = false

              console.log('response.data')
              console.log(response.data)
              // const userData = response.data.data

              // eslint-disable-next-line no-unused-vars
              const {
                accessToken, userData, refreshToken, agentData,
              } = response.data

              useJwt.setToken(accessToken)
              useJwt.setRefreshToken(refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              store.commit('appConfig/UPDATE_MENU_SERVICE', userData.userType)
              if (userData.userType === 'root') {
                localStorage.setItem('role', 'root')
                this.$ability.update(rootAbility())
                localStorage.setItem('ability', JSON.stringify(rootAbility()))
              } else if (userData.userType === 'agent') {
                if (agentData !== null) {
                  localStorage.setItem('agentId', agentData._id)
                  localStorage.setItem('agentData', JSON.stringify(agentData))
                }
                localStorage.setItem('role', 'agent')
                this.$ability.update(agentAbility())
                localStorage.setItem('ability', JSON.stringify(agentAbility()))
              }
              // // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.userType))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$t('Welcome')} ${userData.firstName} ${userData.lastName}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `${this.$t('You have successfully logged in')}!`,
                    },
                  })
                }).catch(err => { console.log(err) })
            })
            .catch(error => {
              // this.$refs.loginForm.setErrors(error.response.data.message)
              this.show = false

              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
